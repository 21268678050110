html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

#search-input { background: none; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-wrapper {
  font-size: 14px;
  line-height: 1.85714286em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Open Sans", 'Helvetica', 'Arial', sans-serif;
  color: #666666;
  font-weight: 400;
}

::selection {
  background: #3f8e92 !important;
}

:root {
  --primary: #2E773A;
}

@media(max-width: 576px) {
  .only-desktop {
    display: none !important;
  }
}
